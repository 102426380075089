import React from "react";
import Layout from "../../components/layout/layout";
import { CheckCircleIcon } from "@heroicons/react/solid";

import SEO from "../../components/seo";

const presentingFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "Two full page ad in souvenir programme",
  "10 invites to the Gala Dinner",
  "Opportunities for Miss Singapore Queens to grace your company's event",
];
const goldFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "One Full page ad in souvenir programme",
  "10 invites to the Gala Dinner",
  "Opportunity for Miss Singapore Queens to grace your company's event",
];
const silverFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "One full page ad in souvenir programme",
  "4 invites to the Gala Dinner",
  "Opportunity for Miss Singapore Queens to grace your company's event",
];
const bronzeFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "One full page ad in souvenir programme",
  "2 invites to the Gala Dinner",
  "Opportunity for Miss Singapore Queens to grace your company's event",
];

const Sponsor = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Cash Sponsor Form" />

        <iframe
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUM0ZPMFRGRVlETkdEQ1FJNU5JSVAyS1k3Mi4u&embed=true"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          style={{
            width: "100%",
            height: "2000px",
            border: "none",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          allowFullScreen
        />
        <div className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl">
          <div className="px-1 pb-5 "></div>
        </div>
      </Layout>
    </>
  );
};

export default Sponsor;
